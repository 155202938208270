<template>
  <div class="config-agent-dialog">
    <w-dialog
      ref="dialogRef"
      :title="ticketTitle + '—配置代理商'"
      width="50%"
      btnWidth="140px"
      top="20vh"
      confirmText="确认新增"
      @wConfirm="handleSure"
    >
      <el-form
        class="add-form"
        ref="formRef"
        :model="formData"
        :rules="formRules"
        labelPosition="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="代理商名称" prop="at_aid">
              <el-select
                v-model="formData.at_aid"
                placeholder="请输入代理商名称或证照信息搜索"
                filterable
                remote
                multiple
                :remote-method="getUserOptions"
              >
                <el-option
                  v-for="item in agentOptions"
                  :key="item.a_id"
                  :label="item.a_name"
                  :value="item.a_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="ticket-title">门票设置</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="优惠类型" prop="at_type">
              <el-select
                v-model="formData.at_type"
                placeholder="请选择优惠类型"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in typeOptions"
                  :key="item.id"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12" class="preferential-policy">
            <el-row :gutter="0">
              <el-col :span="15">
                <el-form-item label="优惠政策" prop="at_free_base_num">
                  <el-input
                    type="number"
                    v-model="formData.at_free_base_num"
                    placeholder=" "
                    clearable
                  >
                    <template #prepend>每买</template>
                    <template #append>张，送</template></el-input
                  >
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item prop="at_free_num" label=" " class="hide">
                  <el-input
                    type="number"
                    v-model="formData.at_free_num"
                    placeholder=" "
                    clearable
                    ><template #append>张</template></el-input
                  >
                </el-form-item></el-col
              >
            </el-row>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="起购数量" prop="at_min_num">
              <el-input
                type="number"
                v-model="formData.at_min_num"
                clearable
                placeholder="请输入起购数量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="限购数量" prop="at_max_num">
              <el-input
                type="number"
                v-model="formData.at_max_num"
                clearable
                placeholder="请输入限购数量"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="门票购买后有效期" prop="at_expire_day">
              <el-input
                type="number"
                v-model="formData.at_expire_day"
                clearable
                placeholder="请输入门票购买后有效期"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代理商可购买时间" prop="useDate">
              <el-date-picker
                v-model="formData.useDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>
// 
<script>
import { ref, reactive } from "vue";
import { ElMessage } from "element-plus";
import { ScenicApi, AgentApi } from "@/plugins/api.js";
import dayjs from "dayjs";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const formData = ref({
      at_aid: "",
      at_type: "",
      at_free_base_num: "",
      at_free_num: "",
      at_min_num: "",
      at_max_num: "",
      at_expire_day: "",
      useDate: "",
    });

    const dialogRef = ref(null);
    const formRules = reactive({
      at_aid: [
        {
          required: true,
          message: "请选择代理商名称",
          trigger: "blur",
        },
      ],

      at_type: [
        {
          required: true,
          message: "请输入优惠类型",
          trigger: "blur",
        },
      ],
      at_free_base_num: [
        {
          required: true,
          message: "请输入购买数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_free_num: [
        {
          required: true,
          message: "请输入赠送数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_min_num: [
        {
          required: true,
          message: "请输入起购数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_max_num: [
        {
          required: true,
          message: "请输入限购数量(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      at_expire_day: [
        {
          required: true,
          message: "请输入门票购买后有效期(正整数)",
          trigger: "blur",
          pattern: /^[1-9]\d*$/,
        },
      ],
      useDate: [
        {
          required: true,
          message: "请选择代理商可购买时间",
          trigger: "change",
        },
      ],
    });

    const typeOptions = ref([]);
    const getDiscountData = () => {
      AgentApi.discountOptions().then((res) => {
        if (res.Code === 200) {
          if (res.Data.length) {
            typeOptions.value = res.Data;
          }
        } else {
          let msg = res.Message ? res.Message : "获取门票数据失败！";
          ElMessage.error(msg);
        }
      });
    };
    const agentOptions = ref([]);
    function getUserOptions(query) {
      query &&
        ScenicApi.agentOptions({
          keywords: query,
        }).then((res) => {
          if (res.Code === 200) {
            agentOptions.value = res.Data || [];
          } else {
            let msg = res.Message ? res.Message : "获取失败！";
            ElMessage.error(msg);
          }
        });
    }
    const ticketTitle = ref("");
    function openDialog(data, title) {
      ticketTitle.value = title;
      getDiscountData();
      formData.value = {
        at_aid: [],
        at_type: "",
        at_free_base_num: "",
        at_free_num: "",
        at_min_num: "",
        at_max_num: "",
        at_expire_day: "",
        useDate: "",
      };
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            const data = JSON.parse(JSON.stringify(formData.value));
            data["at_stime"] = dayjs(formData.value.useDate[0]).format(
              "YYYY-MM-DD"
            );
            data["at_etime"] = dayjs(formData.value.useDate[1]).format(
              "YYYY-MM-DD"
            );
            data["at_aid"] = data["at_aid"].join(",");
            if (+data["at_min_num"] > +data["at_max_num"]) {
              dialogRef.value.isLoading = false;
              ElMessage.warning("起购数量不能大于限购数量！");
              return false;
            }
            emit("submit", data);
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      typeOptions,
      getUserOptions,
      agentOptions,
      ticketTitle,
    };
  },
};
</script>

<style lang="scss">
.config-agent-dialog {
  .ticket-title {
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 20px;
  }
  .el-input-group__append {
    border: 1px solid #fff;
  }
  .el-input-group__prepend {
    border: 1px solid #fff;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    color: var(--text-gray-color);
    background-color: #fff !important;
  }
  .hide {
    > label {
      visibility: hidden;
    }
  }
}
</style>
