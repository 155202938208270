<template>
  <div class="stmanage main-cnt">
    <div class="title">门票列表</div>
    <div class="content">
      <common-table
        ref="ticketTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="ScenicApi.ticketList"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="ticketEdit"
        @projectChange="projectChange"
        @netConfig="openNetConfig"
        @configTickets="openConfigTickets"
      >
        <template #st_local_pass="{ row }">
          <template
            v-if="authData.indexOf('n_REVeb87zcccGMmgA3fYmvmjKIRT2') != -1"
          >
            <template v-if="row.st_sttid == 2">
              <el-popconfirm
                :title="`确定要${
                  row.st_local_pass == 1 ? '关闭' : '开启'
                }原住民通道状态吗?`"
                @confirm="localStatusChange(row)"
              >
                <template #reference>
                  <el-switch
                    v-model="row.st_local_pass"
                    inline-prompt
                    active-text="开"
                    inactive-text="关"
                    :width="45"
                    :active-value="1"
                    :inactive-value="2"
                    :before-change="() => false"
                  />
                </template>
              </el-popconfirm>
            </template>
          </template>
          <template v-else>
            <el-switch
              @click="noAuthSwitch"
              :disabled="true"
              v-model="row.st_local_pass"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :width="45"
              :active-value="1"
              :inactive-value="2"
              :before-change="() => false"
            />
          </template>
        </template>
        <template #mini_status="{ row }">
          <template
            v-if="authData.indexOf('FUpL8TuKVQruZVVkWK0AuoHNdOSi') != -1"
          >
            <el-popconfirm
              :title="`确定要${
                row.mini_status == 1 ? '关闭' : '开启'
              }小程序状态吗?`"
              @confirm="miniStatusChange(row)"
            >
              <template #reference>
                <el-switch
                  v-model="row.mini_status"
                  inline-prompt
                  active-text="开"
                  inactive-text="关"
                  :width="45"
                  :active-value="1"
                  :inactive-value="2"
                  :before-change="() => false"
                />
              </template>
            </el-popconfirm>
          </template>
          <template v-else>
            <el-switch
              @click="noAuthSwitch"
              :disabled="true"
              v-model="row.mini_status"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :width="45"
              :active-value="1"
              :inactive-value="2"
              :before-change="() => false"
            />
          </template>
        </template>
        <template #st_agent_status="{ row }">
          <template
            v-if="authData.indexOf('n_s5hVZZepj46WYYLtSsbyz00sE6f') != -1"
          >
            <el-popconfirm
              :title="`确定要${
                row.st_agent_status == 1 ? '关闭' : '开启'
              }代理商状态吗?`"
              @confirm="angenttatusChange(row)"
            >
              <template #reference>
                <el-switch
                  v-model="row.st_agent_status"
                  inline-prompt
                  active-text="开"
                  inactive-text="关"
                  :width="45"
                  :active-value="1"
                  :inactive-value="2"
                  :before-change="() => false"
                />
              </template>
            </el-popconfirm>
          </template>
          <template v-else>
            <el-switch
              @click="noAuthSwitch"
              :disabled="true"
              v-model="row.st_agent_status"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :width="45"
              :active-value="1"
              :inactive-value="2"
              :before-change="() => false"
            />
          </template>
        </template>

        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('n_H2kmpl7gK5YYb27Mzq4ipStFx3C0') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增门票</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑 门票弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑门票' : '新增门票'"
      width="65%"
      btnWidth="140px"
      top="10vh"
      :modalClick="false"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="addConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="st_pid">
          <el-select
            v-model="ruleForm.st_pid"
            clearable
            :disabled="isEdit"
            placeholder="请选择所属项目"
            @change="getScenicByProject"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属景区" prop="st_sid">
          <el-select
            v-model="ruleForm.st_sid"
            clearable
            :disabled="isEdit"
            placeholder="请选择所属景区"
            @change="scenicChange"
          >
            <el-option
              :label="item.s_name"
              :value="item.s_id"
              v-for="item in scenicOptions"
              :key="item.s_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可通行景点" prop="passSpot">
          <el-select
            multiple
            collapse-tags
            v-model="ruleForm.passSpot"
            clearable
            placeholder="请选择可通行景点"
            @change="passSpotChange"
          >
            <el-option
              :label="item.name"
              :value="item.keyValue"
              v-for="item in passSpotOptions"
              :key="item.keyValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-divider></el-divider>
        <el-form-item label="门票名称" prop="st_name">
          <el-input
            v-model="ruleForm.st_name"
            placeholder="请输入门票名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="门票类型" prop="st_sttid">
          <el-select
            :disabled="isEdit"
            v-model="ruleForm.st_sttid"
            clearable
            placeholder="请选择门票类型"
          >
            <el-option
              :label="item.stt_name"
              :value="item.stt_id"
              v-for="item in ticketOptions"
              :key="item.stt_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门票售卖日期" prop="sellDate">
          <el-date-picker
            v-model="ruleForm.sellDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.st_sttid == 2"
          label="门票使用天数"
          prop="st_use_days"
        >
          <el-input
            v-model="ruleForm.st_use_days"
            type="number"
            placeholder="请输入门票使用天数"
          ></el-input>
        </el-form-item>
        <el-form-item v-else label="门票使用日期" prop="useDate">
          <el-date-picker
            v-model="ruleForm.useDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.st_sttid == 3 || ruleForm.st_sttid == 4"
          label="销售模式"
          prop="st_sales_type"
        >
          <el-select
            v-model="ruleForm.st_sales_type"
            clearable
            placeholder="请选择销售模式"
          >
            <el-option label="仅活动" :value="2"></el-option>
            <el-option label="单独售卖" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="门票售价" prop="st_price">
          <el-input
            v-model="ruleForm.st_price"
            type="number"
            placeholder="请输入门票售价"
          ></el-input>
        </el-form-item>
        <el-form-item label="门票原价" prop="st_original_price">
          <el-input
            v-model="ruleForm.st_original_price"
            type="number"
            placeholder="请输入门票原价"
          ></el-input>
        </el-form-item>
        <el-form-item label="门票库存" prop="st_stock">
          <el-input
            v-model="ruleForm.st_stock"
            type="number"
            placeholder="请输入门票库存"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签1" prop="st_tag1">
          <el-input
            v-model="ruleForm.st_tag1"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签2" prop="st_tag2">
          <el-input
            v-model="ruleForm.st_tag2"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <el-form-item label="特色标签3" prop="st_tag3">
          <el-input
            v-model="ruleForm.st_tag3"
            placeholder="请输入标签名"
          ></el-input>
        </el-form-item>
        <!-- 上传封面 -->
        <el-form-item class="block" label="上传封面" prop="cover_files">
          <img-upload
            :limit="1"
            :fileList="fileList"
            @uploadFile="uploadFile"
            @deleteFile="deleteFile"
            suggestText="建议尺寸140*140px；格式要求png，不超过2MB"
          ></img-upload>
        </el-form-item>
        <el-form-item class="block" label="费用包含" prop="st_cost_includes">
          <div ref="costEditorElem"></div>
        </el-form-item>
        <el-form-item
          class="block"
          label="预定须知"
          prop="st_booking_instructions"
        >
          <div ref="noticeEditorElem"></div>
        </el-form-item>
        <el-form-item
          v-if="spotList.length > 0"
          class="number"
          label="通行次数"
        >
          <div class="spot-wp">
            <div
              class="spot-item"
              v-for="(item, index) in spotList"
              :key="index"
            >
              <div class="spot-number">
                <div class="name">{{ item.name }}</div>
                <template
                  v-if="ruleForm.st_sttid == 2 || ruleForm.st_sttid == 4"
                >
                  <el-input :disabled="true" placeholder="无限次"></el-input>
                </template>
                <template v-else
                  ><el-input
                    v-model="item.number"
                    placeholder="请输入允许进场次数"
                  ></el-input
                ></template>
              </div>
              <div class="spot-number">
                <div class="name">分账比例(%)</div>
                <el-input-number
                  v-model="item.percent"
                  :precision="2"
                  placeholder="请输入该景点分账比例"
                  :max="getPerCent(item.percent, index)"
                ></el-input-number>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-divider
          :style="{ marginTop: spotList.length > 0 ? '15px' : '30px' }"
        ></el-divider>
        <div class="filter-wp">
          <el-select
            v-model="ruleVal"
            placeholder="请选择规则"
            @change="getRefundRules"
          >
            <el-option label="通用规则" :value="1"></el-option>
            <el-option label="活动规则" :value="2"></el-option>
          </el-select>
        </div>
        <el-form-item class="radios" label="选择退款规则" prop="st_rrid">
          <el-radio-group v-if="ruleList.length > 0" v-model="ruleForm.st_rrid">
            <el-radio
              :label="item.rr_id"
              size="large"
              v-for="item in ruleList"
              :key="item.rr_id"
              >{{ item.rr_desc }}</el-radio
            >
          </el-radio-group>
          <div
            v-else
            style="
              text-align: center;
              height: 50px;
              line-height: 50px;
              color: #ccc;
            "
          >
            暂无数据！
          </div>
        </el-form-item>
      </el-form>
    </w-dialog>
    <NetConfig ref="netConfig" @submit="handleNetConfig"></NetConfig>
    <ConfigAgentDialog
      ref="configAgentDialogRef"
      @reloadTable="reloadTable"
    ></ConfigAgentDialog>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  toRaw,
  watch,
  computed,
} from "vue";
import { ElMessage } from "element-plus";
import { BasicApi, ScenicApi } from "@/plugins/api.js";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
// 引入富文本编辑器
import * as Qiniu from "qiniu-js";
import Editor from "wangeditor";
import { useStore } from "vuex";
import NetConfig from "../components/NetConfig.vue";
import ConfigAgentDialog from "../components/ConfigAgentDialog.vue";
const store = useStore();
const qiniuData = computed(() => store.state.Config.qiniuData);
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "p_id",
    value: "",
    placeholder: "请选择项目",
    options: [],
    val: "p_id",
    lab: "p_name",
    action: "projectChange",
  },
  {
    filterType: "select",
    name: "s_id",
    value: "",
    placeholder: "请选择景区",
    options: [],
    val: "s_id",
    lab: "s_name",
  },
  {
    filterType: "select",
    name: "stt_id",
    value: "",
    placeholder: "请选择门票类型",
    options: [],
    val: "stt_id",
    lab: "stt_name",
  },
  {
    filterType: "select",
    name: "st_status",
    value: "",
    placeholder: "请选择门票状态",
    options: [
      {
        label: "在售",
        value: 1,
      },
      {
        label: "停售",
        value: 2,
      },
    ],
  },
  {
    filterType: "search",
    name: "keywords",
    value: "",
    placeholder: "输入关键字搜索",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "st_id",
    label: "ID",
    minWidth: 60,
    color: "--text-third-color",
  },
  {
    prop: "st_name",
    label: "门票名称",
    minWidth: 160,
    color: "--text-color",
  },
  {
    prop: "belong_name",
    label: "所属景区",
    minWidth: 120,
    color: "--text-third-color",
    showTooltip: true,
  },
  {
    prop: "stt_name",
    label: "门票类型",
    minWidth: 130,
    color: "--text-color",
  },
  {
    prop: "use_info",
    label: "使用期限",
    minWidth: 220,
    color: "--text-third-color",
  },
  {
    prop: "st_price",
    label: "门票售价",
    minWidth: 80,
    color: "--theme-color",
  },
  {
    prop: "all_stock",
    label: "库存",
    minWidth: 80,
    color: "--text-third-color",
  },
  {
    prop: "buyed_stock",
    label: "已售",
    minWidth: 60,
    color: "--warning-color",
  },
  {
    prop: "buyed_stock_today",
    label: "今日已售",
    minWidth: 80,
  },
  {
    prop: "st_stock",
    label: "余票",
    minWidth: 100,
    color: "--text-color",
  },
  {
    type: "switch",
    prop: "st_status",
    label: "门票状态",
    minWidth: 80,
    token: "n_tlVebS7zLWGmmGHA8KQmvmjPFGR1",
  },
  // {
  //   type: "customRender",
  //   prop: "st_local_pass",
  //   label: "原住民通道",
  //   minWidth: 100,
  // },
  {
    type: "customRender",
    prop: "mini_status",
    label: "小程序状态",
    minWidth: 100,
  },
  {
    type: "customRender",
    prop: "st_agent_status",
    label: "代理商状态",
    minWidth: 100,
  },
  {
    type: "block",
    prop: "agent_count",
    label: "已配置数量",
    active: "configTickets",
    minWidth: 100,
    token: "n_DyEcOPaqFDADPD4cpzyyvoDsBeL",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 200,
    bottons: [
      {
        name: "入账配置",
        action: "netConfig",
        token: "n_rOB1TCxDYnFE6ZMNuXoAkG8lfQsh",
        className: "black-font-btn",
      },
      {
        name: "编辑",
        action: "edit",
        token: "n_QH7bI2wldlELqutYbcCOs67VoIU4",
        className: "theme-font-btn",
      },
    ],
  },
]);
// 已配置列表
const configAgentDialogRef = ref(null);
function openConfigTickets(row) {
  configAgentDialogRef.value.openDialog(row.st_id, row.st_name);
}
/** 餐厅表格数据 */
const ticketTable = ref(null);
/** 当前操作行数据 */
const currentRow = ref(null);
/** 项目选择更改 */
const projectChange = (obj) => {
  filters.value[1].value = "";
  filters.value[1].options = [];
  reloadTable();
  if (obj.value) {
    // 获取景区列表数据
    BasicApi.getScenicByProject({ pid: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    st_id: row.st_id,
    st_status: row.st_status == 1 ? 2 : 1,
  };
  ScenicApi.ticketStatusEdit(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("门票状态修改成功！");
      // 重新获取门票列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "门票状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
const localStatusChange = (row) => {
  let data = {
    st_id: row.st_id,
    st_local_pass: row.st_local_pass == 1 ? 2 : 1,
  };
  ScenicApi.localStatusEdit(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("原住民通道状态修改成功！");
      // 重新获取门票列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "门原住民通道状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
const angenttatusChange = (row) => {
  let data = {
    st_id: row.st_id,
    agent_status: row.st_agent_status == 1 ? 2 : 1,
  };
  ScenicApi.setAgentStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("代理商状态修改成功！");
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "代理商状态修改失败！";
      ElMessage.error(msg);
    }
  });
};

const miniStatusChange = (row) => {
  let data = {
    st_id: row.st_id,
    mini_status: row.mini_status == 1 ? 2 : 1,
  };
  ScenicApi.miniStatusEdit(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("小程序状态修改成功！");
      // 重新获取门票列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : "小程序状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
function noAuthSwitch() {
  ElMessage.warning("您没有操作权限！");
}
function reloadTable() {
  ticketTable.value.tableLoad();
}
function getPerCent(val, currentIndex) {
  let maxPercent = 0;
  spotList.value && spotList.value.length && result;
  spotList.value.forEach((el, index) => {
    if (index != currentIndex) {
      maxPercent += el.percent;
    }
  });
  maxPercent = Math.round(+maxPercent * 100) / 100;
  const result = 100 - +maxPercent;
  return result;
}
/** 表格编辑 */
const ticketEdit = (row) => {
  ruleList.value = [];
  passSpotOptions.value = [];
  spotList.value = [];
  ruleForm.passSpot = [];
  isEdit.value = true;
  addDialog.value.show();
  store.dispatch("getQiniuData");
  // 获取门票详情
  ScenicApi.ticketInfo({ st_id: row.st_id }).then((res) => {
    if (res.Code === 200) {
      currentRow.value = res.Data;
      ruleForm.st_pid = res.Data.p_name; // 项目
      ruleForm.st_sid = res.Data.s_name; // 所属景区

      ruleForm.st_name = res.Data.st_name; // 门票名称
      ruleForm.st_sttid = res.Data.st_sttid; // 门票类型
      ruleForm.sellDate = [res.Data.st_sell_stime, res.Data.st_sell_etime]; // 售票日期
      if (res.Data.st_use_stime && res.Data.st_use_etime) {
        ruleForm.useDate = [res.Data.st_use_stime, res.Data.st_use_etime];
      } else {
        ruleForm.useDate = [];
      }
      ruleForm.st_use_days = res.Data.st_use_days; // 使用天数
      ruleForm.st_sales_type = res.Data.st_sales_type; // 销售模式
      ruleForm.st_price = res.Data.st_price; // 门票售价
      ruleForm.st_original_price = res.Data.st_original_price; // 门票原价
      ruleForm.st_stock = res.Data.st_stock; // 门票库存
      ruleForm.st_tag1 = res.Data.st_tag1; // 特色标签1
      ruleForm.st_tag2 = res.Data.st_tag2; // 特色标签2
      ruleForm.st_tag3 = res.Data.st_tag3; // 特色标签3
      ruleForm.cover_files = [res.Data.sf_path]; // 文件列表
      if (res.Data.sf_path) {
        fileList.value = [
          { file_url: res.Data.sf_http_url, key: res.Data.sf_path },
        ];
      } else {
        fileList.value = [];
      }
      ruleForm.st_cost_includes = res.Data.st_cost_includes; // 费用包含
      ruleForm.st_booking_instructions = res.Data.st_booking_instructions; // 预订须知
      ruleForm.images = res.Data.images || [];

      ruleVal.value = res.Data.rr_type;
      if (ruleVal.value) {
        // 获取退费规则
        getRefundRules();
      }
      ruleForm.st_rrid = res.Data.st_rrid; // 退费规则

      scenicOptions.value = [];
      costEditorInit();
      noticeEditorInit();
      if (res.Data.bind.length) {
        res.Data.bind.forEach((el) => {
          if (el.stb_type == 1) {
            el.keyValue = el.stb_type + "-" + el.stb_sid;
          } else {
            el.keyValue = el.stb_type + "-" + el.stb_spid;
          }
          ruleForm.passSpot.push(el.keyValue);
        });
      }
      const currentBindData = [];
      if (res.Data.st_sid) {
        BasicApi.rolebindList({ s_id: res.Data.st_sid }).then((result) => {
          if (result.Code === 200) {
            if (result.Data && result.Data.length > 0) {
              result.Data.forEach((item) => {
                if (item.type == 1) {
                  item.id = item.s_id;
                  item.keyValue = item.type + "-" + item.s_id;
                  item.name = "景区-" + item.s_name;
                } else {
                  item.id = item.sp_id;
                  item.keyValue = item.type + "-" + item.sp_id;
                  item.name = "景点-" + item.sp_name;
                }
                res.Data.bind.length &&
                  res.Data.bind.forEach((el) => {
                    if (el.keyValue == item.keyValue) {
                      item.number = el.stb_access_num;
                      item.percent = el.stb_percent ? +el.stb_percent : 0;
                      currentBindData.push(item);
                    }
                  });
              });
            }

            passSpotOptions.value = result.Data ? result.Data : [];
            spotList.value = currentBindData;
          } else {
            let msg = result.Message ? result.Message : "获取景点数据失败！";
            ElMessage.error(msg);
          }
        });
      }
    } else {
      let msg = res.Message ? res.Message : "获取门票详情数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 项目列表数据 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      projectOptions.value = res.Data ? res.Data : [];
      filters.value[0].options = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 景区列表数据 */
const scenicOptions = ref([]);
/** 获取项目下景区数据 */
const getScenicByProject = (value) => {
  ruleForm.st_sid = "";
  scenicOptions.value = [];
  spotList.value = [];
  passSpotOptions.value = [];
  ruleForm.passSpot = [];
  ruleList.value = [];
  if (value) {
    BasicApi.getScenicByProject({ pid: value }).then((res) => {
      if (res.Code === 200) {
        scenicOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 景点/景区通行次数列表 */
const spotList = ref([]);
/** 景区选择更改 */
const passSpotOptions = ref([]);
const scenicChange = (value) => {
  spotList.value = [];
  passSpotOptions.value = [];
  ruleForm.passSpot = [];
  ruleList.value = [];
  if (value) {
    BasicApi.rolebindList({ s_id: value }).then((res) => {
      if (res.Code === 200) {
        if (res.Data && res.Data.length > 0) {
          res.Data.forEach((item) => {
            if (item.type == 1) {
              item.id = item.s_id;
              item.keyValue = item.type + "-" + item.s_id;

              item.name = "景区-" + item.s_name;
            } else {
              item.id = item.sp_id;
              item.keyValue = item.type + "-" + item.sp_id;
              item.name = "景点-" + item.sp_name;
            }
            item.number = "";
            item.percent = 0;
          });
        }
        passSpotOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取景点数据失败！";
        ElMessage.error(msg);
      }
    });
    if (ruleVal.value) {
      // 获取退费规则
      getRefundRules();
    }
  }
};
function passSpotChange(passSpotArr) {
  const result = [];
  passSpotOptions.value.length &&
    passSpotOptions.value.forEach((el) => {
      if (passSpotArr.indexOf(el.keyValue) != -1) {
        result.push(el);
      } else {
        el.number = "";
        el.percent = 0;
      }
    });
  spotList.value = result;
}
/** 门票类型列表 */
const ticketOptions = ref([]);
/** 获取门票类型 */
const getTicketTypes = () => {
  ScenicApi.ticketType().then((res) => {
    if (res.Code === 200) {
      filters.value[2].options = res.Data ? res.Data : [];
      ticketOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取门票类型数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  st_pid: "", // 项目
  st_sid: "", // 所属景区
  st_name: "", // 门票名称
  st_sttid: "", // 门票类型
  sellDate: [], // 售票日期
  useDate: [], // 使用日期
  st_use_days: "", // 使用天数
  st_sales_type: "", // 销售模式
  st_price: "", // 门票售价
  st_original_price: "", // 门票原价
  st_stock: "", // 门票库存
  st_tag1: "", // 特色标签1
  st_tag2: "", // 特色标签2
  st_tag3: "", // 特色标签3
  cover_files: [], // 文件列表
  st_cost_includes: "", // 费用包含
  st_booking_instructions: "", // 预订须知
  st_rrid: "", // 退费规则
});
/** 表单规则对象 */
const rules = reactive({
  st_pid: [
    {
      required: true,
      message: "请选择项目",
      trigger: "change",
    },
  ],
  st_sid: [
    {
      required: true,
      message: "请选择所属景区",
      trigger: "change",
    },
  ],
  st_name: [
    {
      required: true,
      message: "请输入门票名称",
      trigger: "blur",
    },
  ],
  st_sttid: [
    {
      required: true,
      message: "请选择门票类型",
      trigger: "change",
    },
  ],
  sellDate: [
    {
      required: true,
      message: "请选择售票日期",
      trigger: "change",
    },
  ],
  useDate: [
    {
      required: true,
      message: "请选择使用日期",
      trigger: "change",
    },
  ],
  st_use_days: [
    {
      required: true,
      message: "请输入使用天数",
      trigger: "blur",
    },
  ],
  st_sales_type: [
    {
      required: true,
      message: "请选择销售模式",
      trigger: "change",
    },
  ],
  st_price: [
    {
      required: true,
      message: "请输入门票售价",
      trigger: "blur",
    },
  ],
  st_original_price: [
    {
      required: true,
      message: "请输入门票原价",
      trigger: "blur",
    },
  ],
  st_stock: [
    {
      required: true,
      message: "请输入门票库存",
      trigger: "blur",
    },
  ],
  // st_tag1: [
  //   {
  //     required: true,
  //     message: "请输入特色标签1",
  //     trigger: "blur",
  //   },
  // ],
  // st_tag2: [
  //   {
  //     required: true,
  //     message: "请输入特色标签2",
  //     trigger: "blur",
  //   },
  // ],
  // st_tag3: [
  //   {
  //     required: true,
  //     message: "请输入特色标签3",
  //     trigger: "blur",
  //   },
  // ],
  cover_files: [
    {
      required: true,
      message: "请上传文件",
      trigger: "change",
    },
  ],
  st_cost_includes: [
    {
      required: true,
      message: "请输入费用包含内容",
      trigger: "change",
    },
  ],
  st_booking_instructions: [
    {
      required: true,
      message: "请输入预定须知内容",
      trigger: "change",
    },
  ],
  st_rrid: [
    {
      required: true,
      message: "请选择退费规则",
      trigger: "change",
    },
  ],
});
/** 弹出 新增/编辑门票 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  passSpotOptions.value = [];
  spotList.value = [];
  ruleForm.passSpot = [];
  ruleForm.st_pid = ""; // 项目
  ruleForm.st_sid = ""; // 所属景区
  ruleForm.st_name = ""; // 门票名称
  ruleForm.st_sttid = ""; // 门票类型
  ruleForm.sellDate = []; // 售票日期
  ruleForm.useDate = []; // 使用日期
  ruleForm.st_use_days = ""; // 使用天数
  ruleForm.st_sales_type = ""; // 销售模式
  ruleForm.st_price = ""; // 门票售价
  ruleForm.st_original_price = ""; // 门票原价
  ruleForm.st_stock = ""; // 门票库存
  ruleForm.st_tag1 = ""; // 特色标签1
  ruleForm.st_tag2 = ""; // 特色标签2
  ruleForm.st_tag3 = ""; // 特色标签3
  ruleForm.cover_files = []; // 文件列表
  ruleForm.st_cost_includes = ""; // 费用包含
  ruleForm.st_booking_instructions = ""; // 预订须知
  ruleForm.images = []; // 预订须知
  ruleForm.st_rrid = ""; // 退费规则
  scenicOptions.value = [];
  ruleVal.value = "";
  ruleList.value = [];
  fileList.value = [];
  addDialog.value.show();
  store.dispatch("getQiniuData");
  nextTick(() => {
    costEditorInit();
    noticeEditorInit();
  });
};
/** 新增/编辑确认处理 */
const addConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        st_name: ruleForm.st_name,
        st_sttid: ruleForm.st_sttid,
        st_sell_stime: ruleForm.sellDate[0],
        st_sell_etime: ruleForm.sellDate[1],
        st_price: ruleForm.st_price,
        st_original_price: ruleForm.st_original_price,
        st_stock: ruleForm.st_stock,
        st_tag1: ruleForm.st_tag1,
        st_tag2: ruleForm.st_tag2,
        st_tag3: ruleForm.st_tag3,
        st_cost_includes: ruleForm.st_cost_includes,
        st_booking_instructions: ruleForm.st_booking_instructions,
        images: ruleForm.images,
        file_type: 1,
        sft_id: 8,
        cover_files: toRaw(ruleForm.cover_files),
        st_rrid: ruleForm.st_rrid,
      };

      if (spotList.value.length) {
        const lastBind = [];
        spotList.value.forEach((itm) => {
          if (itm.type == 1) {
            lastBind.push({
              stb_sid: itm.id,
              stb_access_num: itm.number,
              stb_percent: itm.percent ? +itm.percent : 0,
            });
          } else {
            lastBind.push({
              stb_spid: itm.id,
              stb_access_num: itm.number,
              stb_percent: itm.percent ? +itm.percent : 0,
            });
          }
        });
        data["bind"] = lastBind;
      }
      if (data["bind"].length) {
        let result = 0;
        data["bind"].forEach((el) => {
          result += +el.stb_percent;
        });
        result = Math.round(+result * 100) / 100;
        if (result != 100) {
          addDialog.value.isLoading = false;
          ElMessage.warning("分账比例总和必须为100！");
          return false;
        }
      }
      if (ruleForm.st_sttid == 2) {
        data.st_use_days = ruleForm.st_use_days;
      } else {
        data.st_use_stime = ruleForm.useDate[0];
        data.st_use_etime = ruleForm.useDate[1];
      }
      if (ruleForm.st_sttid == 3 || ruleForm.st_sttid == 4) {
        data.st_sales_type = ruleForm.st_sales_type;
      }
      let url = "ticketInsert";
      if (isEdit.value) {
        url = "ticketEdit";
        data.st_id = currentRow.value.st_id;
      } else {
        data.st_pid = ruleForm.st_pid;
        data.st_sid = ruleForm.st_sid;
      }
      ScenicApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        const text = isEdit.value ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`门票${text}成功！`);
          addDialog.value.close();
          // 重新获取门票列表数据
          reloadTable();
        } else {
          let msg = res.Message ? res.Message : `门票${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 文件列表 */
const fileList = ref([]);
/** 文件上传 回调 */
const uploadFile = (data) => {
  fileList.value.push({ file_url: data.standard_url, key: data.key });
  ruleForm.cover_files.push(data.key);
  addForm.value.validateField("cover_files");
};
/** 文件删除回调 */
const deleteFile = (data) => {
  let index1 = fileList.value.findIndex((item) => item.key === data.key);
  let index2 = ruleForm.cover_files.findIndex((item) => item === data.key);
  fileList.value.splice(index1, 1);
  ruleForm.cover_files.splice(index2, 1);
  addForm.value.validateField("cover_files");
};

/** 费用包含 富文本容器 */
const costEditorElem = ref(null);
/** 入住政策 富文本实例对象 */
const costEditor = ref(null);
// 入住政策 富文本初始化
const costEditorInit = () => {
  costEditor.value = new Editor(costEditorElem.value);
  costEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  costEditor.value.config.onchange = (newHtml) => {
    ruleForm.st_cost_includes = newHtml;
    addForm.value.validateField("st_cost_includes");
  };
  // 自定义菜单配置
  costEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ]; // 限制上传图片格式
  costEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  costEditor.value.config.qiniu = true;
  costEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  costEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `ticket-cost-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            costEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            ruleForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  costEditor.value.config.height = 300;
  // 创建富文本实例
  costEditor.value.create();
  costEditor.value.txt.html(ruleForm.st_cost_includes);
};

/** 预定须知 富文本容器 */
const noticeEditorElem = ref(null);
/** 预定须知 富文本实例对象 */
const noticeEditor = ref(null);
// 预定须知 富文本初始化
const noticeEditorInit = () => {
  noticeEditor.value = new Editor(noticeEditorElem.value);
  noticeEditor.value.config.colors = [
    "#000000",
    "#eeece0",
    "#1c487f",
    "#4d80bf",
    "#0072f6",
    "#1a1a1a",
    "#FFB900",
    "#EB5774",
    "#f2f2f2",
    "#666666",
    "#999999",
    "#cccccc",
  ];
  // 配置 onchange 回调函数
  noticeEditor.value.config.onchange = (newHtml) => {
    ruleForm.st_booking_instructions = newHtml;
    addForm.value.validateField("st_booking_instructions");
  };
  // 自定义菜单配置
  noticeEditor.value.config.menus = [
    "head",
    "bold",
    "fontSize",
    "fontName",
    "italic",
    "underline",
    "strikeThrough",
    "indent",
    "lineHeight",
    "foreColor",
    "justify",
    "quote",
    "splitLine",
    "image",
    "undo",
    "redo",
  ]; // 限制上传图片格式
  noticeEditor.value.config.uploadImgAccept = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
  ];
  noticeEditor.value.config.qiniu = true;
  noticeEditor.value.config.showLinkImg = false; // 开启本地上传图片(这是后端上传链接)
  const upToken = qiniuData.value.token;
  const domain = qiniuData.value.http_domain;
  noticeEditor.value.config.customUploadImg = (file) => {
    if (file.length) {
      file.forEach((el) => {
        const myDate = new Date();
        const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
        const timeData = Date.parse(new Date()); //当前时间时时间戳
        const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
        const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
        const filename = el.name;
        const index = filename.lastIndexOf(".");
        const suffix = filename.substr(index + 1);
        const key = `ticket-notice-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
        const config = {
          // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
          cdnUphost: domain,
        };
        const putExtra = {
          fname: "", //文件原文件名
          params: {}, //用来放置自定义变量
          mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
        };
        const observable = Qiniu.upload(el, key, upToken, putExtra, config);
        observable.subscribe({
          error: () => {},
          complete: (res) => {
            const sourceLink = domain + res.key;
            noticeEditor.value.cmd.do(
              "insertHtml",
              '<img src="' + sourceLink + '" style="max-width:100%;"/>'
            );
            ruleForm.images.push({
              filename: filename,
              qiniu_key: res.key,
              size: el.size,
            });
          },
        });
      });
    }
  };

  noticeEditor.value.config.height = 300;
  // 创建富文本实例
  noticeEditor.value.create();
  noticeEditor.value.txt.html(ruleForm.st_booking_instructions);
};

/** 退费规则绑定值 */
const ruleVal = ref("");
/** 退费规则列表 */
const ruleList = ref([]);
/** 获取退费规则数据 */
const getRefundRules = () => {
  if (ruleForm.st_sid) {
    let data = {
      type: ruleVal.value,
      sid: isEdit.value ? currentRow.value.st_sid : ruleForm.st_sid,
    };
    BasicApi.getRefundRules(data).then((res) => {
      if (res.Code === 200) {
        ruleList.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取退费规则数据失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("请选择景区");
  }
};
const netConfig = ref(null);
function openNetConfig(row) {
  netConfig.value.openDialog("sticket", row);
}
function handleNetConfig(data) {
  ScenicApi.setTicketAccount(data).then((res) => {
    netConfig.value.closeDialogLoading();
    if (res.Code === 200) {
      ElMessage.success(`操作成功！`);
      netConfig.value.closeDialog();
      // 重新获取门票列表数据
      reloadTable();
    } else {
      let msg = res.Message ? res.Message : `操作失败！`;
      ElMessage.error(msg);
    }
  });
}

onMounted(() => {
  // 获取项目数据
  getProjectData();
  // 获取门票类型
  getTicketTypes();
  reloadTable();
});
</script>

<style lang="scss">
.stmanage {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .el-input-group__append {
          border: none;
          background-color: var(--search-uncheck-bg-color) !important;
          color: var(--text-gray-color);
        }
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 23px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
          .el-form-item.block {
            width: 100%;
          }
          .el-form-item.number {
            padding: 23px 0 0;
            width: 100%;
            .el-form-item__label {
              margin-left: 15px;
            }
            .spot-wp {
              display: flex;
              flex-wrap: wrap;
              .spot-item {
                width: 100%;
                margin: 0 15px 15px;
                border: 1px dashed #ccc;
                border-radius: 5px;
                padding: 15px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                .spot-number {
                  display: flex;
                  .name {
                    margin-right: 10px;
                  }
                  .el-input {
                    flex: 1;
                  }
                }
              }
            }
          }
          .el-form-item.radios {
            width: 100%;
            .el-radio-group {
              width: 100%;
              .el-radio {
                display: flex;
                white-space: pre-wrap;
                .el-radio__input {
                  .el-radio__inner {
                    width: 16px;
                    height: 16px;
                  }
                }
                .el-radio__label {
                  flex: 1;
                  line-height: 20px;
                }
              }
            }
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
          .filter-wp {
            width: 33.3%;
            padding: 20px 15px 0;
            > .el-select {
              margin-right: 15px;
              width: 192px;
            }
          }
        }
      }
    }
  }
}
</style>
