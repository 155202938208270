<template>
  <div class="configured-agent-dialog">
    <w-dialog
      ref="dialogRef"
      :title="ticketTitle + '—已配置数量'"
      width="80%"
      btnWidth="140px"
      top="5vh"
      :hideFooter="true"
    >
      <common-table
        ref="tableRef"
        tableHeight="calc(100vh - 320px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="ScenicApi.getAgentList"
        :extraParame="{ st_id: ticketId }"
        :columns="tableColumns"
        @edit="openTicketsDialog"
        @statusChange="changeAgentStatus"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="openTicketsDialog"
            v-if="authData.indexOf('n_7UJRFoXfobMvNq0CTTQ8PerSryu') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            配置代理商</el-button
          >
        </template>
      </common-table>
      <AgentDialog ref="agentDialogRef" @submit="submitAgent"></AgentDialog>
      <div class="tips">
        关闭门票展示状态，代理商将无法购买该优惠政策的门票。
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { ref, nextTick, computed, watch } from "vue";
import { ElMessage } from "element-plus";
import { ScenicApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import AgentDialog from "./AgentDialog.vue";
export default {
  components: {
    AgentDialog,
  },
  emits: ["reloadTable"],
  setup(props, { emit }) {
    const store = useStore();
    const menuTokens = computed(() => store.state.menuToken.menuTokens);
    const authData = ref([]);
    watch(
      () => menuTokens.value,
      (data) => {
        if (data.length) {
          authData.value = data;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const dialogRef = ref(null);
    const ticketId = ref(null);
    const ticketTitle = ref(null);
    function openDialog(id, title) {
      ticketId.value = id;
      ticketTitle.value = title;
      dialogRef.value.show();
      nextTick(() => {
        tableRef.value.tableLoad();
      });
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }

    /** 表格对象 */
    const tableRef = ref(null);

    /** 表格配置数据 */
    const tableColumns = ref([
      {
        prop: "auto_id",
        label: "序号",
        color: "--text-third-color",
        minWidth: 50,
      },
      {
        prop: "agent_name",
        label: "代理商名称",
        minWidth: 140,
      },

      {
        prop: "free_msg",
        label: "优惠",
        color: "--text-third-color",
        minWidth: 150,
      },
      {
        prop: "at_min_num",
        label: "起购数量",
      },
      {
        prop: "at_max_num",
        label: "限购数量",
      },
      {
        prop: "at_expire_day_mag",
        label: "使用期限",
        color: "--text-third-color",
        minWidth: 140,
      },
      {
        prop: "at_stime",
        label: "可购时间",
        color: "--text-third-color",
        prop2: "at_etime",
        type: "connect",
        connectText: "至",
        minWidth: 240,
      },
      {
        prop: "at_ctime",
        label: "添加时间",
        color: "--text-third-color",
        minWidth: 120,
      },
      {
        type: "switch",
        label: "展示状态",
        prop: "at_status",
        token: "n_t6vz3rzaWW8MVn0niEUudVYxmqO",
      },
    ]);

    const agentDialogRef = ref(null);

    const openTicketsDialog = (row) => {
      const rowData = row.mc_id ? row : {};
      agentDialogRef.value.openDialog(rowData, ticketTitle.value);
    };

    function changeAgentStatus(row) {
      let data = {
        at_id: row.at_id,
        at_status: row.at_status == 1 ? 2 : 1,
      };
      ScenicApi.setConfigAgentStatus(data).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("开启成功！");
          tableRef.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "开启失败！";
          ElMessage.error(msg);
        }
      });
    }
    function submitAgent(data) {
      const parmas = {
        ...data,
        at_stid: ticketId.value,
      };

      ScenicApi.addAgentConfig(parmas).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("配置成功！");
          tableRef.value.tableLoad();
          agentDialogRef.value.closeDialogLoading();
          agentDialogRef.value.closeDialog();
          emit("reloadTable", true);
        } else {
          let msg = res.Message ? res.Message : "配置失败！";
          agentDialogRef.value.closeDialogLoading();
          ElMessage.error(msg);
        }
      });
    }

    return {
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      authData,
      tableColumns,
      tableRef,
      agentDialogRef,
      openTicketsDialog,
      changeAgentStatus,
      ScenicApi,
      ticketTitle,
      ticketId,
      submitAgent,
    };
  },
};
</script>

<style lang="scss">
.configured-agent-dialog {
  .tips {
    text-align: right;
    color: var(--text-gray-color);
  }
}
</style>
